/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ICompanyInfo } from 'commons/types';
import { IPackingTask } from 'commons/types/packingTask.interface';

import { InvoicePrintTemplateComponent } from './InvoicePrintTemplate.component';

const InvoicePrintTemplate: React.FC = () => {
  const [searchParams] = useSearchParams();
  const packingTask: IPackingTask = JSON.parse(searchParams.get('task') || '');
  const company: ICompanyInfo = JSON.parse(searchParams.get('company') || '');

  useEffect(() => {
    document.body.className = 'print-template';
  }, []);

  // early return if no data yet
  if (!packingTask) return <div />;

  return (
    <InvoicePrintTemplateComponent
      packingTask={packingTask}
      company={company}
    />
  );
};

export default InvoicePrintTemplate;
