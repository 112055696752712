import React from 'react';

import { PRIMARY_RED, PRIMARY_YELLOW } from 'commons/styles/colors';
import { TableConfig } from 'commons/types';

import { IDestinationSummary } from '../types';

export const destinationSummaryConfig: TableConfig = [
  {
    header: 'Total Requested Qty',
    key: 'total_requested_quantity',
  },
  {
    header: 'Source Picked Qty',
    element: (item: IDestinationSummary): React.ReactElement => {
      if (item.source_picked_quantity < item.total_requested_quantity) {
        return (
          <div style={{ color: `${PRIMARY_YELLOW}` }}>
            {item.source_picked_quantity}
          </div>
        );
      }
      return <div>{item.source_picked_quantity}</div>;
    },
  },
  {
    header: 'Replenished Qty',
    element: (item: IDestinationSummary): React.ReactElement => {
      if (item.replenished_quantity < item.source_picked_quantity) {
        return (
          <div style={{ color: `${PRIMARY_YELLOW}` }}>
            {item.replenished_quantity}
          </div>
        );
      } else if (item.replenished_quantity > item.source_picked_quantity) {
        return (
          <div style={{ color: `${PRIMARY_RED}` }}>
            {item.replenished_quantity}
          </div>
        );
      }
      return <div>{item.replenished_quantity}</div>;
    },
  },
];
