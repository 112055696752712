/* eslint-disable react/no-array-index-key */
import React from 'react';
import QRCode from 'react-qr-code';

import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { DateTime } from 'luxon';

import { BasketProductDetails } from 'commons/types/packingTask.interface';

import { BasePrintComponentProps } from '../interfaces';

import S from './TTFPrintTemplate.styles';

type TTFPrintTemplateComponentProps = BasePrintComponentProps;

export const TTFPrintTemplateComponent = ({
  packingTask,
  company,
}: TTFPrintTemplateComponentProps) => {
  const renderSignatureSection = (withDateTime = false) => (
    <S.SignatureSection container>
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12} textAlign="center">
            Yang Menyerahkan,
          </Grid>
          <Grid item xs={12}>
            <S.Void className={withDateTime ? 'bigger' : ''} />
            <S.UnderLine />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={4}>
        <Grid container>
          <Grid item xs={12} textAlign="center">
            Yang Menerima,
          </Grid>
          {withDateTime && (
            <>
              <Grid item xs={7}>
                tanggal:
              </Grid>
              <Grid item xs={5}>
                jam:
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <S.Void />
            <S.UnderLine />
          </Grid>
        </Grid>
      </Grid>
    </S.SignatureSection>
  );

  const renderBody = () => {
    const items =
      packingTask?.basket_infos.reduce<BasketProductDetails[]>(
        (result, basketInfo) => [...result, ...basketInfo.items],
        [],
      ) || [];
    const totalAmount = items.reduce(
      (total, productInfo) =>
        total +
        productInfo.price *
          ((100 - productInfo.discount) / 100) *
          productInfo.total_qty_picked *
          (1 + productInfo.ppn),
      0,
    );

    return (
      <>
        <Grid container>
          <Grid item xs={6}>
            <b>SWIPE RX</b>
          </Grid>
          <Grid item xs={6} textAlign="right">
            {packingTask.picking_task.pharmacy?.name || ''}
          </Grid>
        </Grid>
        {/* TUKAR FAKTUR SECTION */}
        <div style={{ textAlign: 'center' }}>
          <S.Title>TANDA TERIMA TUKAR FAKTUR</S.Title>
        </div>
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                fontFamily: 'Arial',
                fontSize: 12,
              },
            }}
            size="small"
            aria-label="a dense table"
          >
            <S.TableHeadStyle>
              <TableRow>
                <S.TableHeaderCell>No</S.TableHeaderCell>
                <S.TableHeaderCell>NO-FAKTUR</S.TableHeaderCell>
                <S.TableHeaderCell>TGL FAKTUR</S.TableHeaderCell>
                <S.TableHeaderCell align="right">JUMLAH</S.TableHeaderCell>
                <S.TableHeaderCell align="right">
                  TGL JT TEMPO
                </S.TableHeaderCell>
                <S.TableHeaderCell>KETERANGAN</S.TableHeaderCell>
              </TableRow>
            </S.TableHeadStyle>
            <S.TableBodyStyle>
              <TableRow
                key="TTFRow"
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <S.TableBodyCell>1</S.TableBodyCell>
                <S.TableBodyCell>{packingTask.invoice_number}</S.TableBodyCell>
                <S.TableBodyCell>
                  {DateTime.fromISO(
                    packingTask.createdAt as unknown as string,
                  ).toFormat('dd/LL/yyyy')}
                </S.TableBodyCell>
                <S.TableBodyCell align="right">
                  {new Intl.NumberFormat('id-ID', {
                    style: 'currency',
                    currency: 'IDR',
                  }).format(totalAmount)}
                </S.TableBodyCell>
                <S.TableBodyCell align="right">
                  {DateTime.fromISO(packingTask.createdAt as unknown as string)
                    .plus({ days: 30 })
                    .toFormat('dd-LLL-yyyy')}
                </S.TableBodyCell>
              </TableRow>
            </S.TableBodyStyle>
          </Table>
        </TableContainer>
        <S.UnderLine />
        {renderSignatureSection(true)}

        {/* FORM RETUR SECTION */}
        <div style={{ textAlign: 'center' }}>
          <S.Title>FORM RETUR</S.Title>
        </div>
        <TableContainer component={Paper} style={{ boxShadow: 'none' }}>
          <Table
            sx={{
              minWidth: 650,
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                fontFamily: 'Arial',
                fontSize: 12,
              },
            }}
            size="small"
            aria-label="a dense table"
          >
            <S.TableHeadStyle>
              <TableRow>
                <S.TableHeaderCell>NO</S.TableHeaderCell>
                <S.TableHeaderCell>NAMA BARANG SKU</S.TableHeaderCell>
                <S.TableHeaderCell>QTY</S.TableHeaderCell>
                <S.TableHeaderCell>ALASAN RETUR BARANG</S.TableHeaderCell>
              </TableRow>
            </S.TableHeadStyle>
          </Table>
        </TableContainer>
        <S.UnderLine />
        <S.UnderLine />
        <S.UnderLine />
        {renderSignatureSection(false)}

        {/* Footer Section */}
        <S.FooterSection container>
          <Grid item xs={7}>
            Note: <br />
            1) Langsung tukar faktur (isi lembar tanda terima tukar <br />
            faktur tanda tangan apotek dan stempel
            <br />
            2) 1 bawa 2 copy faktur
          </Grid>
          <Grid item xs={5}>
            Pembayaran melalui: <br />
            BCA: {company?.va_bca} <br />
            Mandiri: {company?.va_mandiri}
            <br />
            Untuk info lebih lanjut hubungi CS di: (021) 50959952
          </Grid>
        </S.FooterSection>
        <QRCode size={64} value={`PT-DLV-${packingTask?.po_number}`} />
      </>
    );
  };

  return (
    <S.PageBody>
      {renderBody()}
      <S.UnderLine className="dashed" />
      {renderBody()}
    </S.PageBody>
  );
};
