import { Grid, TableBody, TableCell, TableHead } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { LIGHT_GRAY } from 'commons/styles/colors';

class TTFPrintTemplateStyle extends CommonComponentStyle {
  PageBody = styled.div`
    padding: 6mm;
    width: 210mm;
    height: 297mm;

    @page {
      size: portrait;
    }
  `;

  Title = styled.h5`
    margin: 8px;
  `;

  TableHeadStyle = styled(TableHead)`
    border: none;
  `;

  TableHeaderCell = styled(TableCell)`
    font-weight: 700 !important;
    padding: 0 8px !important;
  `;

  TableBodyStyle = styled(TableBody)`
    border: none;
  `;

  TableBodyCell = styled(TableCell)`
    line-height: 100% !important;
    padding: 4px 8px !important;
    vertical-align: top !important;
  `;

  UnderLine = styled.hr`
    width: 100%;
    border: 1px solid ${LIGHT_GRAY};
    margin-top: 16px;

    &.dashed {
      border: 1px dashed ${LIGHT_GRAY};
    }
  `;

  Void = styled.div`
    height: 5mm;

    &.bigger {
      height: 10mm;
    }
  `;

  SignatureSection = styled(Grid)`
    font-size: 12px;
    margin-top: 16px;
  `;

  FooterSection = styled(Grid)`
    font-size: 12px;
    margin: 8px 0;
  `;
}

export default new TTFPrintTemplateStyle();
