import { Grid, TableBody, TableCell, TableHead } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class InvoicePrintTemplateStyle extends CommonComponentStyle {
  PageWrapper = styled.div`
    padding: 8mm;
    width: 210mm;
    height: 297mm;
    @media print {
      size: portrait;

      .pagebreak-after {
        page-break-after: always !important;
      }

      .print-barcode {
        display: block;
        page-break-inside: avoid;
      }
    }
  `;

  PageHeader = styled.div`
    width: 210mm;
    margin-top: 8mm;

    &:nth-of-type(1) {
      margin-top: 0;
    }
  `;

  PageBody = styled.div`
    margin-top: 8px;
    page-break-inside: auto;
  `;

  PageFooter = styled.div<{ pageOffset?: number }>`
    position: absolute;
    width: 210mm;
    height: 30mm;
    bottom: 0;

    &.last-page {
      margin-bottom: -${(props) => (props.pageOffset ? props.pageOffset * 100 : 0)}vh;
    }
  `;

  PageNumberText = styled.p`
    font-size: 12px;
    margin-top: 8mm;
  `;

  DigitalSignatureLabel = styled.div`
    color: red;
    border: 1px solid red;
    width: 50%;
    padding: 8px 0;
    margin-left: 8px;
  `;

  FakturLabel = styled.div`
    font-weight: 700;
    text-align: right;
    font-size: 18px;
  `;

  InvoiceCopeWrapper = styled(Grid)`
    border: 1px solid black;
    font-size: 14px;
    margin-top: 8px;
  `;

  CompanyProfileWrapper = styled(Grid)`
    padding: 4px 8px;
  `;

  CompanyAddressText = styled.div`
    white-space: pre-line;
    line-height: 60%;
    margin: 2px 0;
  `;

  CustomerInfoWrapper = styled(Grid)`
    border: 1px solid black;
    font-size: 12px;
    margin-top: 8px;
  `;

  CustomerInfoContent = styled(Grid)`
    margin: 8px 0 !important;
    padding-left: 8px;
    white-space: pre-line;
    line-height: 60%;
  `;

  TableHeadStyle = styled(TableHead)`
    border: 1px solid black;
  `;

  TableHeaderCell = styled(TableCell)`
    font-weight: 700 !important;
  `;

  TableBodyStyle = styled(TableBody)`
    border: 1px solid black;
  `;

  TableBodyCell = styled(TableCell)`
    line-height: 100% !important;
    padding: 4px 8px !important;
    vertical-align: top !important;
  `;

  SummarySectionWrapper = styled(Grid)`
    font-size: 14px;
    margin-top: 16px;
  `;

  SignatureSectionWrapper = styled(Grid)`
    margin-top: 24px !important;
  `;

  HandOverSectionWrapper = styled(Grid)`
    margin-top: 48px !important;
  `;
}

export default new InvoicePrintTemplateStyle();
