/* eslint-disable no-plusplus */
/* eslint-disable class-methods-use-this */
import { PickingTaskStatusEnum } from 'commons/enums';
import { OrderType, PriorityList } from 'commons/types';
import {
  BasketProductDetails,
  IPackingTask,
  KoliInfo,
} from 'commons/types/packingTask.interface';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import { IGetPackTaskResponseDTO } from './interfaces';
import { IGetAllocationSuggestionResponseDTO } from './interfaces/get-allocation-suggestion-response-dto.interface';

const price = [10000, 20021, 30000];
const disc = [0, 0, 1, 2, 3];
const basketProductDetails: BasketProductDetails[] = [];
for (let i = 0; i < 8; i++) {
  basketProductDetails.push({
    sku_code: `ID10000-${i + 1}`,
    name: `Product 0${i} 50MG 1SACHET`,
    uom: 'DUS, 10 STRIP @ 10 KAPLET',
    quantity_ordered: 10,
    total_qty_picked: 10,
    check_passed: true,
    price: price[i % 3],
    discount: disc[i % 5],
    ppn: 0.11,
    is_modified: false,
    reason: '',
    pick_actual: [
      {
        basket: 5,
        warehouse_id: 1994,
        sku_code: `ID10000-${i + 1}`,
        bin: 'A1B1',
        bin_type: 'retail',
        quantity: 6,
        inventory_number: '202607-COL01A1 0',
        expiry_date: new Date('2027-10-30'),
      },
      {
        basket: 5,
        warehouse_id: 1994,
        sku_code: `ID10000-${i + 1}`,
        bin: 'A1B1',
        bin_type: 'retail',
        quantity: 4,
        inventory_number: '202607-COL01A1 Z',
        expiry_date: new Date('2027-10-30'),
      },
    ],
  });
}

const dummyPackTask: IPackingTask = {
  warehouse_id: 1994,
  po_number: 'PO1234567',
  packer: '1',
  logistic_name: 'Borzo',
  invoice_number: 'TMP-INV-2024/01/01 123456',
  invoice_id: 123456,
  koli: [{ weight: 1.1 }, { weight: 1.1 }, { weight: 1.1 }],
  allocation_staging: '',
  basket_infos: [
    {
      basket: 5,
      items: basketProductDetails,
      photo_url: [],
      is_scanned: true,
    },
    {
      basket: 8,
      items: basketProductDetails,
      photo_url: [],
      is_scanned: true,
    },
  ],
  picking_task: {
    status: PickingTaskStatusEnum.PACKING,
    picker: '1',
    priority: PriorityList.P1,
    is_digital_signed: false,
    po_type: OrderType.PRECURSOR,
    pharmacy: {
      pt_id: 3,
      name: 'MClinica TestingMClinica TestingMClinica TestingMClinica TestingMClinica TestingMClinica',
      address: 'Jalan Jalan Beli Petasan 123',
      city: 'Jakarta',
      district: 'A',
      subdistrict: 'a',
      zipcode: '12345',
    },
    baskets: [5, 8],
  },
  createdAt: new Date(),
  updatedAt: new Date(),
};

class SwipeRxWmsPackingTask extends SwipeRxWmsApi {
  private readonly basePath: string;

  constructor() {
    super(SwipeRxAPIResources.PACKING_TASK);
    this.basePath = `/${this.apiResource}`;
  }

  async getPackingTask(): Promise<IGetPackTaskResponseDTO> {
    try {
      // const result = await this.get(`${this.apiResource}`, undefined);

      // if (!result.data) throw new Error('[FEError] Data not found');
      return {
        data: dummyPackTask,
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getPackingTaskByBasket(
    basket: number,
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.get(
        `${this.basePath}/get-packlist-by-basket/${basket}`,
      );

      if (!result.data) throw new Error('[FEError] Data not found');

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getPackerOnProgressPackingTask(): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.get(`${this.basePath}/get-on-progress-task`);

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateBasketInfo(basket: number): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.patch(
        `${this.basePath}/update-basket-info/${basket}`,
        undefined,
      );

      if (!result.data) throw new Error('[FEError] Update Failed');

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateQtyPicked(
    poNumber: string,
    payload: BasketProductDetails & { basket: number },
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.patch(
        `${this.basePath}/edit-qty/${poNumber}`,
        payload,
      );
      if (!result.data) throw new Error('[FEError] Update Qty Failed');
      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async completeChecking(poNumber: string): Promise<IGetPackTaskResponseDTO> {
    try {
      const result = await this.post(`${this.basePath}/complete-checking`, {
        po_number: poNumber,
      });

      if (!result.data) throw new Error('[FEError] Complete Check failed');

      return result;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateKoliInfo(
    poNumber: string,
    koli: KoliInfo[],
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      // const result = await this.get(`${this.apiResource}`, undefined);

      // if (!result.data) throw new Error('[FEError] Data not found');
      return {
        data: { ...dummyPackTask, koli },
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getAllocationStagingSuggestion(
    warehouseId: number,
  ): Promise<IGetAllocationSuggestionResponseDTO> {
    try {
      // const result = await this.get(`${this.apiResource}`, undefined);

      // if (!result.data) throw new Error('[FEError] Data not found');
      return {
        data: 'Pallete Borzo 3',
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async updateAllocationStaging(
    poNumber: string,
    allocationStaging: string | null,
  ): Promise<IGetPackTaskResponseDTO> {
    try {
      // const result = await this.get(`${this.apiResource}`, undefined);

      // if (!result.data) throw new Error('[FEError] Data not found');
      return {
        data: { ...dummyPackTask, allocation_staging: allocationStaging },
      };
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swipeRxWmsAPIPackingTask = new SwipeRxWmsPackingTask();
