import React from 'react';

import { Add, Close } from '@mui/icons-material';
import { Grid, IconButton } from '@mui/material';

import * as S from 'commons/components/Table/Table.styles';
import { IPickItemActual } from 'commons/types';
import { EditQtyTableProps } from 'commons/types/editQtyData.interface';

import { CheckBasketStyle as SB } from '../CheckBasket.styles';

import { BasketInfoDataTable } from './BasketInfoDataTable.component';

const Row: React.FC<{
  data: IPickItemActual;
  name: string;
  uom: string;
  index: number;
  quantityOrdered: number;
  totalQtyPicked: number;
  handleRemove: (deleteItem: IPickItemActual) => void;
  handlePickActual: (index: number, editItem: IPickItemActual) => void;
}> = ({
  data,
  name,
  uom,
  index,
  totalQtyPicked,
  quantityOrdered,
  handleRemove,
  handlePickActual,
}) => {
  const onFieldChange = (key: keyof IPickItemActual, value: string): void => {
    const clonedData = structuredClone(data);
    if (key === 'inventory_number' || key === 'bin') {
      clonedData[key] = value;
    }
    if (key === 'quantity') {
      if (!Number.isNaN(+value)) {
        const fieldQuantity = value && +value > 0 ? +value : 0;

        // check if total qty after changing value is bigger
        const diff = fieldQuantity - clonedData.quantity;
        const totalQtyPickedResult = totalQtyPicked + diff;
        const maxAddQuantity = quantityOrdered - totalQtyPicked;

        clonedData.quantity =
          totalQtyPickedResult > quantityOrdered
            ? clonedData.quantity + maxAddQuantity
            : fieldQuantity;
      }
    }
    handlePickActual(index, clonedData);
  };
  return (
    <S.TableRow>
      <SB.RemoveBatchColumn>
        <IconButton onClick={() => handleRemove(data)}>
          <Close fontSize="small" />
        </IconButton>
      </SB.RemoveBatchColumn>
      <SB.EditTableColumn>{name}</SB.EditTableColumn>
      <SB.EditTableColumn>{uom}</SB.EditTableColumn>
      <SB.EditTableColumn>
        <SB.EditQty
          name="bin"
          value={data.bin}
          onChange={(event) => {
            onFieldChange('bin', event.currentTarget.value);
          }}
        />
      </SB.EditTableColumn>
      <SB.EditTableColumn>
        <SB.EditQty
          name="inventory_number"
          value={data.inventory_number}
          onChange={(event) => {
            onFieldChange('inventory_number', event.currentTarget.value);
          }}
        />
      </SB.EditTableColumn>
      <SB.EditTableColumn>
        <SB.EditQty
          name="quantity"
          value={data.quantity}
          onChange={(event) => {
            onFieldChange('quantity', event.currentTarget.value);
          }}
          inputProps={{
            sx: {
              textAlign: 'right',
            },
          }}
        />
      </SB.EditTableColumn>
    </S.TableRow>
  );
};

const EditQtyTable: React.FC<EditQtyTableProps> = (params): React.ReactNode => {
  const {
    rowTitles,
    data,
    name,
    uom,
    updateBasketDetail,
    quantityOrdered,
    totalQtyPicked,
  } = params;
  const handleRemoveBatch = (deleteItem: IPickItemActual): void => {
    if (data.length > 1) {
      const newData = data.filter((item) => item !== deleteItem);
      updateBasketDetail({
        pick_actual: newData,
        total_qty_picked: newData.reduce((sum, item) => sum + item.quantity, 0),
      });
    }
  };
  const handleAddBatch = (): void => {
    const newItem: IPickItemActual = {
      ...data[data.length - 1],
      bin: '',
      inventory_number: '',
      quantity: 0,
    };
    // setPickActuals([...pickActuals, newItem]);
    updateBasketDetail({
      pick_actual: [...data, newItem],
    });
  };
  const handleEditPickActual = (
    index: number,
    editItem: IPickItemActual,
  ): void => {
    const newData = structuredClone(data);
    newData[index] = editItem;
    updateBasketDetail({
      pick_actual: newData,
      total_qty_picked: newData.reduce((sum, item) => sum + item.quantity, 0),
    });
  };
  return (
    <>
      <SB.TableWrapper>
        <BasketInfoDataTable
          handleCheckAll={() => {}}
          checkAll={false}
          rowTitles={rowTitles}
        >
          <S.TableBody>
            {data.map((item, index) => (
              <Row
                name={name}
                uom={uom}
                data={item}
                index={index}
                handleRemove={handleRemoveBatch}
                handlePickActual={handleEditPickActual}
                quantityOrdered={quantityOrdered}
                totalQtyPicked={totalQtyPicked}
              />
            ))}
          </S.TableBody>
        </BasketInfoDataTable>
      </SB.TableWrapper>
      <Grid container style={{ margin: 8, width: 'inherit' }}>
        <Grid item xs={12} style={{ textAlign: 'right' }}>
          <SB.BlueButtonText onClick={handleAddBatch}>
            <Add /> ADD BATCH
          </SB.BlueButtonText>
        </Grid>
      </Grid>
    </>
  );
};

export default EditQtyTable;
