/* eslint-disable no-plusplus */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ICompanyInfo } from 'commons/types';
import { IPackingTask } from 'commons/types/packingTask.interface';

import { InvoicePrintTemplateComponent } from '../invoice-template';
import { TTFPrintTemplateComponent } from '../ttf-template';

const InvoiceTTFPrintTemplate: React.FC = () => {
  const [searchParams] = useSearchParams();
  const packingTask: IPackingTask = JSON.parse(searchParams.get('task') || '');
  const company: ICompanyInfo = JSON.parse(searchParams.get('company') || '');
  const [ttfPageBreak, setTtfPageBreak] = useState<React.JSX.Element[]>([]);

  useEffect(() => {
    document.body.className = 'print-template';
  }, []);

  const setInvoiceMaxPageNumber = (pageNumber: number) => {
    // set ttf page break
    const pageBreak: React.JSX.Element[] = [];
    for (let i = 0; i < pageNumber - 1; i++) {
      pageBreak.push(<div style={{ pageBreakAfter: 'always' }} />);
    }
    setTtfPageBreak(pageBreak);
  };

  // early return if no data yet
  if (!packingTask) return <div />;

  return (
    <>
      <InvoicePrintTemplateComponent
        packingTask={packingTask}
        company={company}
        setMaxPageNumber={setInvoiceMaxPageNumber}
      />
      {ttfPageBreak}
      <TTFPrintTemplateComponent packingTask={packingTask} company={company} />
    </>
  );
};

export default InvoiceTTFPrintTemplate;
