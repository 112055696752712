/* eslint-disable camelcase */
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { jwtDecode } from 'jwt-decode';
import qs from 'query-string';

import { KeycloakToken } from './interfaces/KeycloakToken.interface';

const {
  REACT_APP_KEYCLOAK_URL,
  REACT_APP_KEYCLOAK_REALM,
  REACT_APP_KEYCLOAK_CLIENT_ID,
  REACT_APP_KEYCLOAK_CLIENT_SECRET,
  REACT_APP_KEYCLOAK_REDIRECT_URI,
  REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URI,
} = process.env;

export class Keycloak {
  protected readonly api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      baseURL: Keycloak.baseURL(),
    });
  }

  static baseURL(): string {
    const keycloakURL = REACT_APP_KEYCLOAK_URL as string;
    const keycloakRealm = REACT_APP_KEYCLOAK_REALM as string;
    return `${keycloakURL}/realms/${keycloakRealm}/protocol/openid-connect`;
  }

  static getRedirectUrl(): string {
    return `${window.location.origin}/code-flow-callback`;
  }

  static getLoginUrl(): string {
    const params = qs.stringify({
      client_id: this.getClientID(),
      client_secret: this.getClientSecret(),
      redirect_uri: REACT_APP_KEYCLOAK_REDIRECT_URI,
      response_type: 'code',
      scope: 'openid',
    });
    return `${this.baseURL()}/auth?${params}`;
  }

  static getLogoutUrl(): string {
    const tokenStr = localStorage.getItem('userToken');
    const idToken = tokenStr
      ? (JSON.parse(tokenStr) as KeycloakToken).id_token
      : '';

    const params = qs.stringify({
      client_id: this.getClientID(),
      client_secret: this.getClientSecret(),
      post_logout_redirect_uri: REACT_APP_KEYCLOAK_LOGOUT_REDIRECT_URI,
      id_token_hint: idToken,
    });
    return `${this.baseURL()}/logout?${params}`;
  }

  static getClientID(): string {
    return REACT_APP_KEYCLOAK_CLIENT_ID as string;
  }

  static getClientSecret(): string {
    return REACT_APP_KEYCLOAK_CLIENT_SECRET as string;
  }

  async getToken(code: string): Promise<KeycloakToken> {
    try {
      const data = this.getPayloadToken(code);
      const config = this.getConfig();

      const {
        data: { access_token, refresh_token, id_token },
      } = await this.api.post<KeycloakToken>('/token', data, config);

      const exp = access_token ? jwtDecode(access_token).exp : undefined;

      return {
        access_token,
        refresh_token,
        id_token,
        exp,
      };
    } catch (error) {
      throw Keycloak.throwError(error as any);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getPayloadToken(code: string): URLSearchParams {
    const params = new URLSearchParams();
    params.append('client_id', Keycloak.getClientID());
    params.append('client_secret', Keycloak.getClientSecret());
    params.append('redirect_uri', Keycloak.getRedirectUrl());
    params.append('grant_type', 'authorization_code');
    params.append('scope', 'openid');
    params.append('code', code);
    return params;
  }

  async refreshToken(refreshToken: string): Promise<KeycloakToken> {
    try {
      console.log('refresh token');
      const payload = this.getPayloadRefreshToken(refreshToken);
      const config = this.getConfig();

      const {
        data: { access_token, refresh_token, id_token },
      } = await this.api.post<KeycloakToken>('/token', payload, config);

      const exp = access_token ? jwtDecode(access_token).exp : undefined;

      return {
        access_token,
        refresh_token,
        id_token,
        exp,
      };
    } catch (error) {
      throw Keycloak.throwError(error as any);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private getPayloadRefreshToken(refreshToken: string): URLSearchParams {
    const params = new URLSearchParams();
    params.append('client_id', Keycloak.getClientID());
    params.append('client_secret', Keycloak.getClientSecret());
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    return params;
  }

  // eslint-disable-next-line class-methods-use-this
  private getConfig(): AxiosRequestConfig {
    return {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
  }

  protected static throwError(e: any): void {
    if (e.response) {
      const { data } = e.response;

      throw new Error(data.error || data.message);
    }

    throw new Error(e.message);
  }
}

export const KeycloakAPI = new Keycloak();
