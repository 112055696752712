import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Grid, TextField } from '@mui/material';

import { Header } from 'commons/components/Header';
import { TableComponent } from 'commons/components/Table';
import {
  completeChecking,
  getPackerOnProgressPackingTask,
  getPackingTaskByBasket,
  updateBasketInfo,
} from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { CheckBasketStyle as S } from './CheckBasket.styles';
import { BasketInfos } from './components';
import { poDataConfig } from './table-configs';

const CheckBasketPage: React.FC = () => {
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );
  const dispatch = useDispatch<AppDispatch>();

  const [basket, setBasket] = useState<number>(0);

  const navigate = useNavigate();

  const onFieldChange = (value: string): void => {
    if (value === '') {
      setBasket(0);
      return;
    }
    const basketValue =
      typeof value === 'string' ? parseInt(value, 10) : (value as number);
    setBasket(basketValue);
  };

  const handleClickCheckDetails = (): void => {
    if (packingTask === null) {
      dispatch(getPackingTaskByBasket(basket));
    } else {
      dispatch(updateBasketInfo(basket));
    }
  };

  const getTotalQtyBySkuCode = (skuCode: string): number => {
    const qtyTotal = packingTask?.basket_infos
      .flatMap((info) =>
        info.items.flatMap((item) => {
          if (item.sku_code === skuCode) {
            return item.total_qty_picked;
          }
          return 0;
        }),
      )
      .reduce((sum, data) => sum + data, 0);
    return qtyTotal ?? 0;
  };

  const handleStartPacking = (): void => {
    dispatch(
      completeChecking({ poNumber: packingTask?.po_number ?? '', navigate }),
    );
  };

  useEffect(() => {
    dispatch(getPackerOnProgressPackingTask());
  }, [dispatch]);

  const isAllCheckPassed = (): boolean => {
    if (packingTask) {
      return packingTask.basket_infos.every((basketInfo) =>
        basketInfo.items.every((item) => item.check_passed),
      );
    } else {
      return false;
    }
  };

  const handleBackToOutboundPage = (): void => {
    navigate('/home/outbound');
  };

  return (
    <div>
      <Header
        title="Check Baskets"
        prevPageHandler={handleBackToOutboundPage}
      />
      <Grid container style={{ padding: '16px 0' }}>
        <Grid xs={9}>
          <TextField
            placeholder="Basket Number"
            fullWidth
            variant="outlined"
            type="number"
            value={basket !== 0 ? basket : ''}
            onChange={(event) => onFieldChange(event.target.value)}
          />
        </Grid>
        <Grid xs={3} style={{ textAlign: 'center' }}>
          <S.PrimaryButton
            onClick={() => handleClickCheckDetails()}
            disabled={basket === 0}
          >
            CHECK DETAILS
          </S.PrimaryButton>
        </Grid>
      </Grid>
      {packingTask ? (
        <div style={{ margin: '16px 0' }}>
          <TableComponent config={poDataConfig} data={[packingTask] || []} />
        </div>
      ) : null}
      <S.ContentWrapper>
        {packingTask?.basket_infos
          .filter((info) => info.is_scanned)
          .map((data, index) => (
            <BasketInfos
              key={data.basket}
              data={data}
              basketIndex={index}
              getTotalQtyBySkuCode={getTotalQtyBySkuCode}
            />
          ))}
        <S.BlankWhiteSpace />
      </S.ContentWrapper>
      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={12}>
            <S.PrimaryButton
              disabled={!isAllCheckPassed()}
              onClick={handleStartPacking}
            >
              START PACKING
            </S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </div>
  );
};

export default CheckBasketPage;
