import React, { useEffect, useState } from 'react';

import { Brightness1, CameraAlt, ExpandMore } from '@mui/icons-material';
import { AccordionDetails, AccordionSummary, Grid } from '@mui/material';

import { PRIMARY_GRAY, PROGRESS_ICON_COLOR } from 'commons/styles/colors';
import { RowTitleProps } from 'commons/types/listPageData.interface';
import { BasketInfo } from 'commons/types/packingTask.interface';

import { CheckBasketStyle as S } from '../CheckBasket.styles';

import { BasketInfoTable } from './BasketInfoTable.component';

interface IBasketInfosProps {
  data?: BasketInfo;
  basketIndex: number;
  getTotalQtyBySkuCode: (skuCode: string) => number;
}

const rowTitles: RowTitleProps[] = [
  { title: 'SKU ID' },
  { title: 'Name' },
  { title: 'UoM' },
  { title: 'Order QTY', isCentered: true },
  { title: 'QTY Picked', isCentered: true },
  { title: 'Mark as Passed', isCentered: true },
];

export const BasketInfos: React.FC<IBasketInfosProps> = ({
  data,
  basketIndex,
  getTotalQtyBySkuCode,
}) => {
  const [progressColorIdx, setProgressColorIdx] = useState<number>(0);
  const countCheckedItems = (): number => {
    if (data) {
      return data.items.reduce(
        (sum, item) => sum + (item.check_passed ? 1 : 0),
        0,
      );
    }
    return 0;
  };

  useEffect(() => {
    const countItemChecked = countCheckedItems();
    if (countItemChecked === 0) {
      setProgressColorIdx(0);
    } else if (countItemChecked === data?.items.length) {
      setProgressColorIdx(1);
    } else {
      setProgressColorIdx(2);
    }
  }, [data]);

  return (
    <Grid container marginBottom="16px" spacing={0}>
      <Grid item xs={12}>
        <S.Accordion defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandMore style={{ color: PRIMARY_GRAY }} />}
            aria-controls={`basket${data?.basket}-content`}
            id={`basket${data?.basket}-header`}
            style={{
              padding: '2px 12px 2px 12px',
              backgroundColor: '#D9D9D926',
            }}
          >
            <S.BasketInfoHeader>
              <div>{`BASKET ${data?.basket}`}</div>
              <S.ProgressSpan>
                <Brightness1
                  style={{
                    fontSize: 14,
                    color: PROGRESS_ICON_COLOR[progressColorIdx],
                  }}
                />{' '}
              </S.ProgressSpan>
              <S.VerticalLine />
              <S.TakePhotoSection>
                {data?.photo_url.length === 0 ? (
                  <>
                    <CameraAlt /> TAKE PHOTO
                  </>
                ) : (
                  <>VIEW PHOTO</>
                )}
              </S.TakePhotoSection>
            </S.BasketInfoHeader>
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0' }}>
            <BasketInfoTable
              rowTitles={rowTitles}
              data={data?.items}
              basketIndex={basketIndex}
              basketNumber={data?.basket || 0}
              getTotalQtyBySkuCode={getTotalQtyBySkuCode}
            />
          </AccordionDetails>
        </S.Accordion>
      </Grid>
    </Grid>
  );
};
