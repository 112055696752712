import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckCircleOutline } from '@mui/icons-material';
import { Grid } from '@mui/material';

import { Header } from 'commons/components/Header';
import * as colors from 'commons/styles/colors';
import { getPackingTask } from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import { PackSummaryStyle as S } from './PackSummary.style';

const PackSummaryPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );

  useEffect(() => {
    if (!packingTask) {
      dispatch(getPackingTask());
    }
  }, [packingTask, dispatch]);

  const prevPageHandler = () => {
    navigate('/pack-task/allocation-staging');
  };

  return (
    <div>
      <Header title="Packing Summary" prevPageHandler={prevPageHandler} />
      <S.HeaderWrapper>
        <S.Header color={colors.PRIMARY_GREEN}>
          <CheckCircleOutline /> Packing Completed
        </S.Header>
        <S.SubHeader>Below is the summary of the task.</S.SubHeader>
      </S.HeaderWrapper>

      <S.ChildContentWrapper>
        <Grid container style={{ fontWeight: 700 }}>
          <Grid item xs={6}>
            PO Number
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            {packingTask?.po_number}
          </Grid>
        </Grid>
        <S.SummaryWrapper container>
          <Grid item xs={6}>
            <S.SummaryName>Invoice Number</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.invoice_number}</S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Picker</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.picking_task?.picker}</S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>3PL</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.logistic_name}</S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Priority Type</S.SummaryName>
          </Grid>
          <Grid
            container
            item
            xs={6}
            justifyContent="flex-end"
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.PriorityCard>
              {packingTask?.picking_task?.priority}
            </S.PriorityCard>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Allocation Staging</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.allocation_staging}</S.SummaryValue>
          </Grid>
          <Grid item xs={6}>
            <S.SummaryName>Koli Number</S.SummaryName>
          </Grid>
          <Grid
            item
            xs={6}
            style={{
              textAlign: 'right',
              verticalAlign: 'middle',
            }}
          >
            <S.SummaryValue>{packingTask?.koli.length}</S.SummaryValue>
          </Grid>
          {packingTask && packingTask.koli.length > 0 ? (
            <S.ItemContentWrapper container>
              {packingTask?.koli.map((info, index) => (
                <>
                  <Grid item xs={6}>
                    <S.SummaryName>{`Koli ${index + 1}`}</S.SummaryName>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      textAlign: 'right',
                      verticalAlign: 'middle',
                    }}
                  >
                    <S.SummaryValue>{`${info.weight} kg`}</S.SummaryValue>
                  </Grid>
                </>
              ))}
            </S.ItemContentWrapper>
          ) : null}
        </S.SummaryWrapper>
        <S.BlankWhiteSpace />
      </S.ChildContentWrapper>

      <S.FooterWrapper>
        <Grid container>
          <Grid item xs={6}>
            <S.SecondaryButton onClick={prevPageHandler}>
              GO BACK
            </S.SecondaryButton>
          </Grid>
          <Grid item xs={6}>
            <S.PrimaryButton>COMPLETE TASK</S.PrimaryButton>
          </Grid>
        </Grid>
      </S.FooterWrapper>
    </div>
  );
};

export default PackSummaryPage;
