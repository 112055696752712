import { Grid } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';

class ShippingLabelPrintTemplateStyle extends CommonComponentStyle {
  PageBody = styled.div`
    margin: 2mm;
    padding-left: 2mm;
    width: 144mm;
    height: 96mm;
    border: 1px solid black;
    font-size: 14px;

    @media print {
      @page {
        size: landscape;
        size: 150mm 100mm;
      }

      .pagebreak-after {
        page-break-after: always;
      }
    }
  `;

  SwipeRxLogo = styled.img`
    width: 75%;
    padding: 8px 8px 8px 0;
  `;

  DigitalSignatureLabel = styled.div`
    color: red;
    border: 1px solid red;
    width: 50%;
    padding: 4px 0;
    margin: 8px;
    margin-left: 8px;
    text-align: center;
  `;

  TopRightInformation = styled(Grid)`
    border: 1px solid black;
    text-align: center;
    font-weight: 700;
  `;

  ContentGrid = styled(Grid)`
    padding: 4px 0;
  `;

  QRCodeWrapper = styled(Grid)`
    margin-top: 72px !important;
    padding-right: 24px;
    text-align: right;
  `;

  PharmacyDetails = styled(Grid)`
    margin-top: 64px !important;
    p {
      font-weight: 700;
      margin: 4px 0;
    }
  `;

  BasketGrid = styled(Grid)`
    border: 1px solid black;
    text-align: center;
    padding: 16px 0;
  `;
}

export default new ShippingLabelPrintTemplateStyle();
