import { NavigateFunction } from 'react-router-dom';

import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  BasketProductDetails,
  KoliInfo,
} from 'commons/types/packingTask.interface';
import { defaultActionProcess } from 'commons/utils/defaultActionProcess';
import { setPackingTask } from 'redux-stores/reducers/packingTaskReducer';
import { swipeRxWmsAPIPackingTask } from 'services/apis/SwipeRxWmsApiPackingTask';

export const getPackingTask = createAsyncThunk(
  'packingTask/getPackingTask',
  async (_, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.getPackingTask();
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const getPackingTaskByBasket = createAsyncThunk(
  'packingTask/getPackingTaskByBasket',
  async (basket: number, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response =
        await swipeRxWmsAPIPackingTask.getPackingTaskByBasket(basket);
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const getPackerOnProgressPackingTask = createAsyncThunk(
  'packingTask/getPackerOnProgressPackingTask',
  async (_, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response =
        await swipeRxWmsAPIPackingTask.getPackerOnProgressPackingTask();
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const updateBasketInfo = createAsyncThunk(
  'packingTask/updateBasketInfo',
  async (basket: number, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.updateBasketInfo(basket);
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const updateQtyPicked = createAsyncThunk(
  'packingTask/updateQtyPicked',
  async (
    params: {
      poNumber: string;
      payload: BasketProductDetails & { basket: number };
      onClose: () => void;
    },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.updateQtyPicked(
        params.poNumber,
        params.payload,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
        params.onClose();
      }
      return response.data;
    }, dispatch);
  },
);

export const completeChecking = createAsyncThunk(
  'packingTask/completeChecking',
  async (
    payload: { poNumber: string; navigate: NavigateFunction },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.completeChecking(
        payload.poNumber,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
        payload.navigate('/pack-task/print');
      }
      return response.data;
    }, dispatch);
  },
);

export const updateKoliInfo = createAsyncThunk(
  'packingTask/updateKoliInfo',
  async (payload: { poNumber: string; koli: KoliInfo[] }, { dispatch }) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.updateKoliInfo(
        payload.poNumber,
        payload.koli,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);

export const getAllocationStagingSuggestion = createAsyncThunk(
  'packingTask/getAllocationStagingSuggestion',
  async (
    payload: { warehouseId: number },
    { dispatch },
  ): Promise<string | null | undefined> => {
    return defaultActionProcess(async () => {
      const response =
        await swipeRxWmsAPIPackingTask.getAllocationStagingSuggestion(
          payload.warehouseId,
        );
      return response.data;
    }, dispatch);
  },
);

export const updateAllocationStaging = createAsyncThunk(
  'packingTask/updateAllocationStaging',
  async (
    payload: { poNumber: string; allocationStaging: string | null },
    { dispatch },
  ) => {
    return defaultActionProcess(async () => {
      const response = await swipeRxWmsAPIPackingTask.updateAllocationStaging(
        payload.poNumber,
        payload.allocationStaging,
      );
      if (response && response.data) {
        dispatch(setPackingTask(response.data));
      }
      return response.data;
    }, dispatch);
  },
);
