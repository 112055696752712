/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import QRCode from 'react-qr-code';
import { useSearchParams } from 'react-router-dom';

import { Grid } from '@mui/material';
import { DateTime } from 'luxon';

import { IPackingTask, KoliInfo } from 'commons/types/packingTask.interface';

import S from './ShippingLabelPrintTemplate.styles';

const ShippingLabelPrintTemplate: React.FC = () => {
  const [searchParams] = useSearchParams();
  const packingTask: IPackingTask = JSON.parse(searchParams.get('task') || '');
  const koli: KoliInfo[] = JSON.parse(searchParams.get('koli') || '');

  useEffect(() => {
    document.body.className = 'print-template';
  }, []);

  // early return if no data yet
  if (!packingTask) return <div />;

  const renderLabel = () => (
    <Grid container>
      {/* Header */}
      <Grid item xs={4}>
        <S.SwipeRxLogo src="/assets/swiperx-logo.svg" alt="Logo" />
      </Grid>
      <Grid item xs={5}>
        <S.DigitalSignatureLabel>
          {packingTask.picking_task.is_digital_signed
            ? 'SP Manual'
            : 'SP Electronic'}
        </S.DigitalSignatureLabel>
      </Grid>
      <S.TopRightInformation item xs={3}>
        <p>{packingTask.logistic_name}</p>
      </S.TopRightInformation>

      {/* Content */}
      <S.ContentGrid item xs={3}>
        Date
      </S.ContentGrid>
      <S.ContentGrid item xs={6}>
        :{' '}
        {DateTime.fromISO(packingTask.createdAt as unknown as string).toFormat(
          'dd LLL yyyy',
        )}
      </S.ContentGrid>
      <S.TopRightInformation item xs={3}>
        {packingTask.picking_task.priority}
      </S.TopRightInformation>
      <S.ContentGrid item xs={3}>
        SO
      </S.ContentGrid>
      <S.ContentGrid item xs={6}>
        : {packingTask.po_number}
      </S.ContentGrid>
      <S.TopRightInformation item xs={3} />
      <S.ContentGrid item xs={3}>
        Pharmacy Name
      </S.ContentGrid>
      <S.ContentGrid item xs={9}>
        : {packingTask.picking_task?.pharmacy?.name || ''}
      </S.ContentGrid>
      <S.ContentGrid item xs={3}>
        Address
      </S.ContentGrid>
      <S.ContentGrid item xs={9}>
        : {packingTask.picking_task?.pharmacy?.address || ''}
      </S.ContentGrid>
      <S.QRCodeWrapper item xs={3}>
        <QRCode value={packingTask.po_number} size={48} />
      </S.QRCodeWrapper>
      <S.PharmacyDetails item xs={9}>
        <p>City: {packingTask.picking_task?.pharmacy?.city || ''}</p>
        <p>District: {packingTask.picking_task?.pharmacy?.district || ''}</p>
        <p>
          Sub-District: {packingTask.picking_task?.pharmacy?.subdistrict || ''}
        </p>
        <p>Postal Code: {packingTask.picking_task?.pharmacy?.zipcode || ''}</p>
      </S.PharmacyDetails>

      {/* Footer */}
      <Grid item xs={8} />
      <S.BasketGrid item xs={4}>
        Basket: {packingTask.picking_task?.baskets?.join(', ')}
      </S.BasketGrid>
    </Grid>
  );

  return koli.map((_, idx) => (
    <S.PageBody>
      {renderLabel()}
      {idx < packingTask.koli.length - 1 && (
        <div
          className="pagebreak-after"
          style={{ marginTop: '2mm', width: '100%' }}
        />
      )}
    </S.PageBody>
  ));
};

export default ShippingLabelPrintTemplate;
