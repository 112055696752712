import React from 'react';

import { capitalize } from '@mui/material';

import { ProductDetail, TableConfig } from 'commons/types';
import { formatDate } from 'commons/utils/formatDate';

export const sourceRecommendationConfig: TableConfig = [
  {
    header: 'Bin Recommendation',
    key: 'bin',
  },
  {
    header: 'Batch',
    key: 'inventory_number',
  },
  {
    header: 'Expiry Date',
    element: (item: ProductDetail): React.ReactElement => {
      const date = formatDate(item.expiry_date);
      return <div>{date}</div>;
    },
  },
  {
    header: 'Type',
    element: (item: ProductDetail): React.ReactElement => {
      const binTypeText = capitalize(item.bin_type.replace('_', ' ') || '');
      return <div>{binTypeText}</div>;
    },
  },
  {
    header: 'Qty',
    key: 'quantity',
  },
];
