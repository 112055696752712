import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckCircle } from '@mui/icons-material';
import { Grid, TextField } from '@mui/material';

import { Header } from 'commons/components/Header';
import { getCompanyInfo } from 'redux-stores/actions/companyInfo.action';
import {
  getPackingTask,
  updateKoliInfo,
} from 'redux-stores/actions/packingTask.action';
import { RootReducerInterface } from 'redux-stores/reducers';
import { AppDispatch } from 'redux-stores/store';

import S from './PackPrintPage.styles';

enum PrintPDFTypeEnum {
  INVOICE_TTF = 'invoice-ttf',
  SHIPPING_LABEL = 'shipping-label',
}

interface KoliInfoUI {
  weight: string;
}

const PackPrintPage: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [koli, setKoli] = useState<KoliInfoUI[]>([]);
  const [isPrinted, setIsPrinted] = useState<{
    [K in PrintPDFTypeEnum]: boolean;
  }>({
    [PrintPDFTypeEnum.INVOICE_TTF]: false,
    [PrintPDFTypeEnum.SHIPPING_LABEL]: false,
  });
  const { packingTask } = useSelector(
    (state: RootReducerInterface) => state.packingTask,
  );
  const { company } = useSelector(
    (state: RootReducerInterface) => state.company,
  );

  useEffect(() => {
    if (!packingTask) {
      dispatch(getPackingTask());
    } else {
      const koliUI = packingTask.koli.map((k) => ({
        weight: k.weight.toString(),
      }));
      setKoli(koliUI);
    }
    if (!company) {
      dispatch(getCompanyInfo());
    }
  }, [packingTask, company, dispatch]);

  /** Early return logic */
  if (!packingTask) return <div />;

  const setKoliNumber = (number: number) => {
    const clonedKoli = structuredClone(koli);
    if (number >= koli.length) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < number - koli.length; i++) {
        clonedKoli.push({ weight: '' });
      }
    } else {
      const removedAmt = koli.length - number;
      clonedKoli.splice(number, removedAmt);
    }
    setKoli(clonedKoli);
    // set is printed to false, because koli value changes
    setIsPrinted({ ...isPrinted, [PrintPDFTypeEnum.SHIPPING_LABEL]: false });
  };

  const setKoliInfo = (index: number, weight: string) => {
    const clonedKoli = structuredClone(koli);
    clonedKoli[index] = { weight };
    setKoli(clonedKoli);
  };

  const proceedToNextPage = () => {
    // update koli info to API
    const updatedKoli = koli.map((k) => ({
      weight: parseFloat(k.weight) || 0,
    }));
    dispatch(
      updateKoliInfo({
        poNumber: packingTask.po_number,
        koli: updatedKoli,
      }),
    ).then(() => {
      navigate('/pack-task/allocation-staging');
    });
  };

  const _getPrintURL = (type: PrintPDFTypeEnum) => {
    switch (type) {
      case PrintPDFTypeEnum.INVOICE_TTF:
        return `/print/invoice-ttf?task=${JSON.stringify(
          packingTask,
        )}&company=${JSON.stringify(company)}`;
      case PrintPDFTypeEnum.SHIPPING_LABEL:
        return `/print/ship-label?task=${JSON.stringify(
          packingTask,
        )}&koli=${JSON.stringify(koli)}`;
      default:
        return '/';
    }
  };

  const printPDF = async (type: PrintPDFTypeEnum) => {
    // trigger print
    // const printedArea = document.getElementById('tes') as any;
    const printedArea = document.createElement('iframe');
    printedArea.src = _getPrintURL(type);
    printedArea.hidden = true;
    document.body.appendChild(printedArea);
    setTimeout(() => {
      printedArea?.contentWindow?.focus();
      printedArea?.contentWindow?.print();
      setTimeout(() => document.body.removeChild(printedArea), 2000);
    }, 1000);

    // mark isPrinted
    setIsPrinted({ ...isPrinted, [type]: true });
  };

  return (
    <div>
      <Header
        title="Packing"
        prevPageHandler={() => {
          navigate('/');
        }}
      />

      {/* Page Content */}
      <Grid container>
        {/* Task Information */}
        <Grid item xs={12}>
          <S.MainInfoWrapper container>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">PO Number</p>
              <p>{packingTask?.po_number}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Invoice Number</p>
              <p>{packingTask?.invoice_number}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Picker</p>
              <p>{packingTask?.picking_task.priority}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">3PL</p>
              <p>{packingTask?.logistic_name}</p>
            </S.MainInfoItem>
            <S.MainInfoItem xs={12} sm={6}>
              <p className="info-title">Priority Type</p>
              <S.PriorityText>
                {packingTask?.picking_task.priority}
              </S.PriorityText>
            </S.MainInfoItem>
          </S.MainInfoWrapper>
        </Grid>

        {/* Printing Section */}
        <Grid item xs={12}>
          <S.PrintInfoWrapper container>
            <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
              <CheckCircle
                sx={{
                  color: isPrinted[PrintPDFTypeEnum.INVOICE_TTF]
                    ? 'primary.main'
                    : 'primary.light',
                }}
                style={{ marginRight: 16 }}
              />
              <div>
                <b>PRINT DOCUMENTS</b> <br /> <br />
                Documents include: Invoice and TTF
              </div>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={3} sm={2} className="align-right">
              <S.PrimaryButton
                onClick={() => printPDF(PrintPDFTypeEnum.INVOICE_TTF)}
              >
                Print
              </S.PrimaryButton>
            </S.PrintInfoItem>
          </S.PrintInfoWrapper>
        </Grid>
        <Grid item xs={12}>
          <S.PrintInfoWrapper container>
            <S.PrintInfoItem item xs={9} sm={10} className="inline-flex">
              <CheckCircle
                sx={{
                  color: isPrinted[PrintPDFTypeEnum.SHIPPING_LABEL]
                    ? 'primary.main'
                    : 'primary.light',
                }}
                style={{ marginRight: 16 }}
              />
              <b>PRINT SHIPPING LABEL</b>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={3} sm={2} className="align-right">
              <S.PrimaryButton
                disabled={
                  !isPrinted[PrintPDFTypeEnum.INVOICE_TTF] || koli.length === 0
                }
                onClick={() => printPDF(PrintPDFTypeEnum.SHIPPING_LABEL)}
              >
                Print
              </S.PrimaryButton>
            </S.PrintInfoItem>
            {/* input koli section */}
            <S.PrintInfoItem item xs={6} sm={4}>
              <p>Koli Number</p>
            </S.PrintInfoItem>
            <S.PrintInfoItem item xs={6} sm={8} className="inline-flex">
              <TextField
                variant="outlined"
                placeholder="0"
                type="number"
                value={koli.length || ''}
                onChange={(e) => setKoliNumber(+e.target.value)}
                inputProps={{
                  sx: {
                    textAlign: 'right',
                    '&::placeholder': {
                      textAlign: 'right',
                    },
                  },
                }}
              />
            </S.PrintInfoItem>

            {/* Commented as currently no need to input weight for koli
                Might be needed in the future. Remove completely if the business
                flow is fixed to not use this section.
            */}
            {/* {koli.map((k, idx) => (
              <>
                <S.PrintInfoItem item xs={6} sm={4}>
                  <p>Koli {idx + 1}</p>
                </S.PrintInfoItem>
                <S.PrintInfoItem item xs={6} sm={8} className="inline-flex">
                  <TextField
                    key={`Koli ${idx + 1}`}
                    variant="outlined"
                    placeholder="0"
                    type="number"
                    value={k.weight || ''}
                    onChange={(e) => setKoliInfo(idx, e.target.value)}
                    inputProps={{
                      sx: {
                        textAlign: 'right',
                        '&::placeholder': {
                          textAlign: 'right',
                        },
                      },
                      min: 0,
                    }}
                  />
                  <span>kg</span>
                </S.PrintInfoItem>
              </>
            ))} */}
          </S.PrintInfoWrapper>
        </Grid>
      </Grid>

      {/* Footer Section */}
      <S.FooterWrapper>
        <S.PrimaryButton
          disabled={
            !isPrinted[PrintPDFTypeEnum.INVOICE_TTF] ||
            !isPrinted[PrintPDFTypeEnum.SHIPPING_LABEL]
          }
          onClick={proceedToNextPage}
        >
          PROCEED
        </S.PrimaryButton>
      </S.FooterWrapper>
      <S.BlankWhiteSpace />
    </div>
  );
};

export default PackPrintPage;
