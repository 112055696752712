import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { Settings as LuxonSettings } from 'luxon';

import { Loading } from 'commons/components/Loading';
import { Snackbar } from 'commons/components/Snackbar';
import { getUserAlertNotification } from 'redux-stores/actions';
import { RootReducerInterface } from 'redux-stores/reducers';
import { snackbarSetOpen } from 'redux-stores/reducers/utilityReducer';
import { AppDispatch } from 'redux-stores/store';

import { AppRouter } from './routes';
import theme from './theme-provider';

const App = (): React.JSX.Element => {
  // FIXME probably better to load from env?
  LuxonSettings.defaultZone = 'Asia/Jakarta';
  const dispatch = useDispatch<AppDispatch>();

  const onSnackbarClose = () => dispatch(snackbarSetOpen(false));

  const snackbar = useSelector(
    (state: RootReducerInterface) => state.utility.snackbar,
  );

  const loading = useSelector(
    (state: RootReducerInterface) => state.utility.loading,
  );

  useEffect(() => {
    dispatch(getUserAlertNotification());
  }, [dispatch]);

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <ThemeProvider theme={theme}>
        <AppRouter />
        <Snackbar
          color={snackbar?.color}
          message={snackbar?.message}
          autoHideDuration={snackbar?.autoHideDuration}
          open={snackbar?.open}
          onClose={onSnackbarClose}
          style={{ whiteSpace: 'pre-line' }}
        />
        <Loading show={loading.show} />
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
