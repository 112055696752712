import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { UserRolesEnum } from 'commons/types/user.roles.enum';
import PrivateRoute from 'commons/utils/privateRoute';
import PackAllocationStaging from 'pages/pack/pack-allocation-staging/PackAllocationStaging';
import CheckBasketPage from 'pages/pack/pack-check-basket/CheckBasket';
import PackPrintPage from 'pages/pack/pack-print-page/PackPrintPage';
import PackSummaryPage from 'pages/pack/pack-summary/PackSummaryPage';

export const usePackTaskRouter = (): React.JSX.Element => (
  <Route
    path="/pack-task"
    element={
      <PrivateRoute
        roles={[
          UserRolesEnum.ADMIN,
          UserRolesEnum.SUPER_ADMIN,
          UserRolesEnum.PACKER,
          UserRolesEnum.PACKER_LEAD,
          UserRolesEnum.PACKER_SPV,
        ]}
      />
    }
  >
    <Route path="/pack-task/print" element={<PackPrintPage />} />
    <Route path="/pack-task/summary" element={<PackSummaryPage />} />
    <Route
      path="/pack-task/allocation-staging"
      element={<PackAllocationStaging />}
    />
    <Route path="/pack-task/check-basket" element={<CheckBasketPage />} />
    <Route path="/pack-task" element={<Navigate to="/" />} />
    <Route path="/pack-task/*" element={<Navigate to="/" />} />
  </Route>
);
