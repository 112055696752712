/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';

import { Dialog } from '@mui/material';

import { IConfirmationDialogProps } from 'commons/types/confirmationDialog.interface';

import * as S from './ConfirmationDialog.styles';

const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
  title,
  content,
  onClose,
  openModal,
  onConfirm,
}) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={openModal}
      style={{ whiteSpace: 'pre-line' }}
    >
      <S.TitleWrapper>
        <S.Title>{title}</S.Title>
      </S.TitleWrapper>

      <S.Text>{content}</S.Text>

      {/* Footer */}
      <S.FooterWrapper>
        <S.CancelButton onClick={onClose}>NO</S.CancelButton>
        <S.OKButton onClick={handleConfirm}>YES</S.OKButton>
      </S.FooterWrapper>
    </Dialog>
  );
};

export default ConfirmationDialog;
