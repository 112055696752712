/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable class-methods-use-this */

import { ReplenishTask } from 'commons/types';

import { SwipeRxWmsApi } from '../resources';
import { SwipeRxAPIResources } from '../resources/types';

import { IGetReplenishTasksRequest } from './get-replenish-tasks-dto.type';
import { IRequestReplenishDTO } from './types';

class SwipeRxWmsApiReplenishList extends SwipeRxWmsApi {
  constructor() {
    super(SwipeRxAPIResources.REPLENISH);
  }

  async requestReplenish(
    payload: IRequestReplenishDTO,
  ): Promise<ReplenishTask[]> {
    try {
      const result = await this.post(`${this.apiResource}/add-queue`, payload);

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async getReplenishList(
    withRecommendation: boolean = false,
  ): Promise<ReplenishTask[]> {
    try {
      const queryParams: IGetReplenishTasksRequest = {
        recommendationMaxCount: 2,
        withRecommendation,
      };
      const result = await this.get(
        `${this.apiResource}/get-tasks`,
        queryParams,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async assignReplenishTask() {
    try {
      const result = await this.post(
        `${this.apiResource}/assign/user/new`,
        undefined,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async addReplenishTask(): Promise<ReplenishTask> {
    try {
      const result = await this.post(
        `${this.apiResource}/assign/user/add`,
        undefined,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async startReplenishProcess(replenishNumbers: string[]) {
    try {
      const result = await this.post(`${this.apiResource}/start-replenish`, {
        replenish_numbers: replenishNumbers,
      });

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }

  async removeReplenishTask(replenishNumber: string) {
    try {
      const result = await this.patch(
        `${this.apiResource}/assign/${encodeURIComponent(replenishNumber)}`,
        undefined,
      );

      if (!result.data) throw new Error('[FEError] Data not found');
      return result.data;
    } catch (error) {
      console.warn(error);
      throw error;
    }
  }
}

export const swipeRxWmsApiReplenishList = new SwipeRxWmsApiReplenishList();
