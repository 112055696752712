import { Grid, TextField } from '@mui/material';
import styled from 'styled-components';

import CommonComponentStyle from 'commons/styles/basic-components.styles';
import { LIGHT_GRAY, PRIMARY_GRAY } from 'commons/styles/colors';

class PackAllocationStagingStyle extends CommonComponentStyle {
  MainInfoWrapper = styled(Grid)`
    font-size: 12px;
    margin: 8px 0 24px 0;
  `;

  MainInfoItem = styled(Grid)`
    p {
      &.info-title {
        color: ${PRIMARY_GRAY};
      }
    }
  `;

  PriorityText = styled.span`
    background: ${this.colors.LIGHT_YELLOW};
    color: ${this.colors.PRIMARY_ORANGE};
    border-radius: 8px;
    padding: 3px 10px;
  `;

  AllocationInfoWrapper = styled(Grid)`
    border-top: 1px solid ${LIGHT_GRAY};
    padding: 8px 0;
  `;

  AllocationInfoTitle = styled.p`
    font-weight: 700;
    font-size: 14px;
  `;

  AllocationInfoTextField = styled(TextField)`
    width: 100%;
    margin-bottom: 8px !important;
  `;

  AllocationInfoSuggestionDiv = styled.div`
    margin: 8px 0;
  `;

  AllocationInfoSuggestionSpan = styled.span`
    margin-right: 24px;
  `;

  SuggestionSecondaryButton = styled(this.SecondaryButton)`
    width: 150px !important;
    ${(props) => (props.hidden ? 'display: none !important;' : '')}
  `;
}

export default new PackAllocationStagingStyle();
